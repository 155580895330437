import React from 'react'
import {useSelector} from "react-redux"
import {Navigate, Outlet} from "react-router-dom"
import { getUser } from '../redux/loginSlice';
// import DashBoard from '../Pages/DashBoard/Dashboard';
// import { getUserPhone } from '../redux/loginSlice';
// import { useEffect, useState } from 'react';
// import { getDatabase, ref, onValue } from "firebase/database";

const ProtectedRouteAdmin = ({children}) => {
    const count = useSelector(getUser);
    const user = JSON.parse(count.user)
    const tokenExp = user?.stsTokenManager?.expirationTime
    const timeNow = new Date().getTime();

    // const phNo = useSelector(getUserPhone);
    // const db = getDatabase();
    // const [userData, setUserData] = useState([])
    // const [isAdmin, setisAdmin] = useState(false)
    // const [TransactionListdata, setTransactionList] = useState([])
    // useEffect(() => {
    //     const starCountRef = ref(db, 'Users/' + phNo + '/data/');
    //     onValue(starCountRef, (snapshot) => {
    //         const data1 = snapshot.val();
    //         if (snapshot.exists()) {
                
    //             // setUserData(data1)
    //             if(data1.role === "admin" || data1.role === "Manager"){
    //                 setisAdmin(true)
    //             }else{
    //                 setisAdmin(false)
    //             }
    //         }
    //     });
        
    // }, [db])

    return(
        timeNow < tokenExp ? <Outlet/> : <Navigate to="/login"/>
    )
};

export default ProtectedRouteAdmin;

// import { Outlet, Navigate } from 'react-router-dom'

// const ProtectedRoute = () => {
//     const count = useSelector(getUser);
//     console.log("protected->",JSON.parse(count.user));
    // return(
    //     count.user ? <Outlet/> : <Navigate to="/login"/>
    // )
// }

// export default ProtectedRoute