import React, { useState } from 'react';
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaPhotoVideo,
    FaHandHoldingHeart,
} from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png';
import { useTranslation } from "react-i18next";
import { TbLogout } from "react-icons/tb";
import { signOut } from "firebase/auth";
import { auth } from '../firbase.config';
import { useDispatch } from 'react-redux';
import { loginUserData, savePhone } from '../redux/loginSlice';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const menuItem = [
        {
            path: "/",
            name: t('Home'),
            icon: <FaTh />
        },
        // {
        //     path: "/profile",
        //     name: t('Profile'),
        //     icon: <FaUserAlt />
        // },
        {
            path: "https://www.google.com/maps/place/Gowri+Prasanna+convention+hall+G.M.B+complex/@13.3419388,77.1199452,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipODRvSpK2srmz1s8ys8VluK1PXaT8PAeQ4_hufG!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipODRvSpK2srmz1s8ys8VluK1PXaT8PAeQ4_hufG%3Dw114-h86-k-no!7i1008!8i756!4m7!3m6!1s0x3bb02d82112bf5df:0x7feeb0fe65039e10!8m2!3d13.3419397!4d77.1199284!10e5!16s%2Fg%2F11qplvkhg8?entry=ttu",
            name: t('Gallery'),
            icon: <FaPhotoVideo />
        },
        {
            path: "/Service",
            name: t('Services'),
            icon: <FaHandHoldingHeart />
        },
        // {
        //     path:"/contact_us",
        //     name:"Contact Us",
        //     icon:<FaPhoneAlt/>
        // },
    ]

    const Logout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            localStorage.setItem('userToken', null)
            localStorage.setItem('userPhone', null)
            dispatch(loginUserData(null))
            dispatch(savePhone(null))
            alert("User logged out successfully")
            navigate('/login', { replace: true });
        }).catch((error) => {
            // An error happened.
            // console.log("trye".error);
        });
    }
    return (
        <div className="SideNavcontainer">
            <div style={{ width: isOpen ? "500px" : "50px" }} className="sidebar">
                <div className="top_section">
                    <img src={logo} style={{ height: 70, display: isOpen ? "block" : "none" }} alt="" />
                    <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">{t('GMB_Complex')}</h1>
                    <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
                        <FaBars onClick={toggle} />
                    </div>
                </div>
                {
                    menuItem.map((item, index) => (
                        <NavLink to={item.path} key={index} className="link" activeclassName="active">
                            <div className="icon">{item.icon}</div>
                            <div title={item.name} style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                        </NavLink>
                    ))
                }
                {/* <div className="top_section"> */}
                    <div className="link" activeclassName="active" onClick={Logout}>
                        <TbLogout style={{ height: 30, width: 30, display: isOpen ? "block" : "none" }} />
                        <div className="link_text" style={{ display: isOpen ? "block" : "none" }} >Logout</div>
                    </div>
                {/* </div> */}
            </div>
            <main>{children}</main>
        </div>
    );
};

export default Sidebar;