import './loader.css'

function Spinner(props) {
    return (
        props.visible && <div className='loader-bg'>
            <div className="loader"></div>
        </div>)
}


export default Spinner;