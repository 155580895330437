import { useState } from 'react';
import logo from '../../assets/logo.png';
import '../Register/Register.css';
import '../../assets/fonts/Poppins-Bold.ttf';
import { getDatabase, ref, set, get, child } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Spinner from '../../Components/Loader';
import { loginUserData, savePhone } from '../../redux/loginSlice';
import { useDispatch } from 'react-redux';

function Register() {
  const [data, setData] = useState({
    name: "",
    number: "",
    email: "",
    city: "",
    area: "",
    role: "user",
    blocked: false,
  });
  const [optVisible, setOtpVisible] = useState(false)
  const [regDisable, setregDisable] = useState(false)
  const [OTP, setOTP] = useState("")
  const [loader, setLoader] = useState(false)
  // const [comfirmpwd, setConfirmpwd] = useState("")
  const db = getDatabase();
  var md5 = require('md5');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = getAuth();

  const getOTP = (e) => {
    setOTP(e.target.value)
  }

  const registerUser = (e) => {
    setregDisable(true)
    setLoader(true)
    window.confirmationResult.confirm(OTP).then((result) => {
      // User signed in successfully.
      createUserWithEmailAndPassword(auth, data.email, md5(data.city))
        .then((userCredential) => {
          setLoader(true)
          // Signed up 
          let timeNow = new Date().getTime();
          set(ref(db, 'Users/+91' + data.number), {
            data
          });
          set(ref(db, 'Emails/' + timeNow), {
            email: data.email
          });
          const userDataString = JSON.stringify(userCredential.user);
          localStorage.setItem('userToken', userDataString)
          localStorage.setItem('userPhone', "+91" + data.number)
          dispatch(loginUserData(userDataString))
          dispatch(savePhone("+91" + data.number))
          navigate('/', { replace: true });
          // ...
        })
        .catch((error) => {
          alert("Something went wrong please try again")
          setLoader(false)
          setregDisable(false)
          // ..
        });
    }).catch((error) => {
      alert("Invalid OTP")
      setLoader(false)
      setregDisable(false)
    });
  }


  // const register = () => {
  //   set(ref(db, 'users/'), {
  //     username: "test",
  //     email: "email",
  //     profile_picture: "imageUrl"
  //   });
  // }


  const handleDataChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  // const handleConfirmChamge = (e) => {
  //   setConfirmpwd(e.target.value)
  // }


  const getUser = async (e) => {
    e.preventDefault();
    setregDisable(true)
    setLoader(true)
    var getEmaildata = true
    if (data.name.trim() === "") {
      alert("Name cannot be empty")
      setLoader(false)
      setregDisable(false)
      setregDisable(false)
    } else if (data.name.trim().length < 3) {
      alert("Enter proper name")
      setLoader(false)
      setregDisable(false)
    } else if (data.number.startsWith("+91")) {
      alert("Please Enter Phone Number Without +91")
      setLoader(false)
      setregDisable(false)
    } else if (data.number.length > 10 || data.number.length < 10) {
      alert("Please Enter Proper Phone Number")
      setLoader(false)
      setregDisable(false)
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      alert("Please Enter Proper email")
      setLoader(false)
      setregDisable(false)
    } else if (data.area.trim() === "") {
      alert('Please Enter Area')
      setLoader(false)
      setregDisable(false)
    } else if (data.city.trim() === "") {
      alert("Please Enter City")
      setLoader(false)
      setregDisable(false)
      // } else if (data.address.trim() === "") {
      //   alert("Please enter proper address")
      //   setLoader(false)
      //   setregDisable(false)
    } else {
      setLoader(true)
      const starCountRef1 = ref(db);
      get(child(starCountRef1, `Emails`)).then ((snapshot1) => {
        const data2 = snapshot1.val();
        if (snapshot1.exists()) {
          var value = Object.values(data2).filter((res) => res.email === data.email)
          if (value.length > 0) {
            getEmaildata = false
          } else {
            getEmaildata = true
          }
        }
      });
      const starCountRef = ref(db);
      get(child(starCountRef, `Users/+91${data.number}/data/`)).then( (snapshot) => {
        const data1 = snapshot.val();
        if (snapshot.exists()) {
          alert("Entered phone number already registered")
          setLoader(false)
          setregDisable(false)
        } else if (data1 === null && getEmaildata) {
          try {
            const recaptcha = new RecaptchaVerifier("recaptcha-container", {
              'size': 'invisible',
              'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // onSignInSubmit();
              }
            }, auth);
            // recaptcha.render();
            // setLoader(false)
            // setregDisable(false)
            signInWithPhoneNumber(auth, "+91" + data.number, recaptcha)
              .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                // recaptcha.clear();
                setOtpVisible(true)
                alert("Otp sent to entered number")
                setLoader(false)
                setregDisable(true)
              }).catch((error) => {
                alert("Something went wrong")
                setLoader(false)
                setregDisable(false)
              });
          } catch (error) {
            alert("Something went wrong")
            setLoader(false)
            setregDisable(false)
          }
        } else {
          alert("Entered email already registered")
          setLoader(false)
          setregDisable(false)
        }
      });
    }
  }


  return (
    <div className="background" style={{ height: window.innerHeight }}>
      <Spinner visible={loader} />
      <div className="" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 60 }}>
          <div>
            <img src={logo} alt="" style={{ width: 80, height: 80, marginRight: 50 }} />
          </div>
          <div>
            <h3 className='logo-name'>GMB COMPLEX</h3>
          </div>
        </div>
        <div >
          <p className="name">
            GOWRI <br />
            PRASANNA <br />
            CONVENTION <br />
            HALL <br />
          </p>
        </div>
      </div>

      {/* <section> */}
      <div className="">
        <div className="row">
          <div className="col-lg-6">
            <div
              className="card bg-warning mb-3"
              style={{ width: 444, backgroundcolor: 'orange' }}
            >
              <div className="card-body">
                <div className="pt-4 pb-2">
                  <h5 className="card-title text-center pb-0 fs-4">REGISTER</h5>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-25">
                      <label for="name">Name:</label>
                    </div>
                    <div className="col-75 textbox">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Your name..."
                        onChange={(e) => handleDataChange(e)}
                        value={data.name}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-25">
                      <label for="number">PH. NO:</label>
                    </div>
                    <div className="col-75">
                      <input
                        type="text"
                        id="number"
                        name="phonenumber"
                        placeholder=""
                        onChange={(e) => handleDataChange(e)}
                        value={data.number}
                        disabled={regDisable}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-25">
                      <label for="email">Email:</label>
                    </div>
                    <div className="col-75">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        placeholder="abc@gmail.com"
                        onChange={(e) => handleDataChange(e)}
                        value={data.email}
                        disabled={regDisable}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-25">
                      <label for="Area">Area:</label>
                    </div>
                    <div className="col-75">
                      <input
                        type="text"
                        id="area"
                        name="area"
                        placeholder="Area"
                        onChange={(e) => handleDataChange(e)}
                        value={data.area}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-25">
                      <label for="City">City:</label>
                    </div>
                    <div className="col-75">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        placeholder="City"
                        onChange={(e) => handleDataChange(e)}
                        value={data.city}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="form-group">
                  <div className="row">
                    <div className="col-25">
                      <label for="address">Address:</label>
                    </div>
                    <div className="col-75">
                      <textarea
                        id="address"
                        name="subject"
                        placeholder="Address"
                        onChange={(e) => handleDataChange(e)}
                        value={data.address}
                      ></textarea>
                    </div>
                  </div>
                </div> */}
                {optVisible && <div className="form-group">
                  <div className="row">
                    <div className="col-25">
                      <label for="number">OTP</label>
                    </div>
                    <div className="col-75">
                      <input
                        type="text"
                        id=""
                        name="phonenumber"
                        placeholder=""
                        onChange={(e) => getOTP(e)}
                        value={OTP}
                      />
                    </div>
                  </div>
                </div>}
                <hr></hr>
                <div id="recaptcha-container" />
                {!optVisible && <><button className="button" onClick={(e) => getUser(e)} disabled={regDisable}>Register</button><a href="/login" style={{ marginLeft: 150 }}>
                  Login
                </a></>}
                {optVisible && <button className="button" onClick={(e) => registerUser()}>Continue</button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Register;
