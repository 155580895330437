import '../Profile/Profile.css';
import '../../assets/fonts/Poppins-Bold.ttf';
// import { TransactionList } from '../../Constants/transactionlist';
import { useSelector } from 'react-redux';
import { getUserPhone } from '../../redux/loginSlice';
import { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from "firebase/database";
import Spinner from '../../Components/Loader';
import Card from '../../Components/Card';

function Profile() {
    const count = useSelector(getUserPhone);
    const db = getDatabase();
    const [userData, setUserData] = useState([])
    const [loader, setLoader] = useState(true)
    const [TransactionListdata, setTransactionList] = useState([])
    const [submittedData, setSubmittedData] = useState(null);
    const [showCard, setShowCard] = useState(false);
    useEffect(() => {
        setLoader(true)
        const starCountRef = ref(db, 'Users/' + count + '/data/');
        onValue(starCountRef, (snapshot) => {
            const data1 = snapshot.val();
            if (snapshot.exists()) {
                setUserData(data1)
                setLoader(false)
            }
        });
        let number = count.startsWith('+91') ? count.substring(3) : count
        const starCountRef1 = ref(db, 'UserTransaction/' + number);
        onValue(starCountRef1, (snapshot1) => {
            const data2 = snapshot1.val();

            if (snapshot1.exists()) {
                const TransactionList = Object.keys(data2).map((bookingId) => {
                    const booking = data2[bookingId].bookingdata;
                    const date = booking?.date;
                    const hallType = booking?.hallType;
                    const amount = booking?.amount  //.replace('/-', ''); // Remove the '/-' from the amount
                    const status = booking?.status === 'Pending' ? 'waiting for approval' : booking.status;
                    const tnxNo = booking?.tnxNo;
                    const url = booking?.url;

                    return {
                        date,
                        hallType,
                        amount,
                        status,
                        tnxNo,
                        url,
                        number: booking?.number,
                        bookingId: booking?.bookingId,
                        name: booking?.name,
                        email: booking?.email,
                        reason: booking?.reason,
                        bookedTime: booking.bookedTime,
                        balance: booking?.balance,
                        paidAmount: booking?.paidAmount,
                        gst: booking?.gst,
                        paymentType: booking?.paymentType,
                        paymentOption: booking?.paymentOption,
                        cheque: booking?.cheque,
                        hallPrice: booking?.hallPrice,
                        functionType: booking?.functionType
                    };
                });
                setTransactionList(TransactionList)

            }
        });
    }, [db])

    // const HandleView = (url) =>{

    // }

    const handleDownload = (data) => {
        setSubmittedData(data)
        setShowCard(true);
    };


    const TransactionComponent = (data) => {
        return (
            <>
                <div className='horizontal-seprator' />
                <div className='transaction-containerlist'>
                    <div className='tranaction-details'>
                        <p style={{ fontSize: 18 }}>{data?.data.date}</p>
                        <p>{data?.data.hallType}</p>
                        <p style={{lineHeight:0.8}}>Amount Paid: <b>{data?.data.paidAmount}</b></p>

                        <p style={{lineHeight:0.8}}>Payment Type: <b>{data?.data?.paymentType}</b></p>


                        {data?.data?.tnxNo ? (<><p style={{lineHeight:0.8}}>Tnx No: <b>{data?.data.tnxNo}</b></p></>) : <></>}


                        {data?.data?.cheque ? (<><p style={{lineHeight:0.8}}>Cheque No: <b>{data?.data.cheque}</b></p></>) : <></>}


                        {data?.data?.reason ? (<><p style={{lineHeight:0.8}}>Reason: {data?.data?.reason}</p></>) : <></>}

                    </div>
                    <div className='status-container'>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', lineHeight: 1 }}>
                            <div>{"Status : "}</div>
                            <p style={{ color: data.data.status === "Confirmed" ? "green" : "orange", fontWeight: 'bold' }}>{" "}{data?.data.status}</p>
                        </div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row' }} onClick={() => handleDownload(data.data)}>
                                <p >Download Receipt</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {data?.data.url !== "" ? <a href={data?.data.url} target='blank'>View Reciept</a> : <></>}
                            </div>
                        </div>

                    </div>
                </div></>
        );
    };
    return (
        <><Card title="Receipt" data={submittedData} isOpen={showCard}
            onClose={() => setShowCard(false)} isAdmin={false}/>
            <div className="background-profile" style={{ height: window.innerHeight }}>
                <Spinner visable={loader} />
                <p className='profile-heading'>PROFILE</p>
                <div className="profile-primary-container">
                    <div className='profile-details-container'>
                        <div className='profile-details'>
                            <p style={{ width: '50%' }}>Name :</p>
                            <p style={{ width: '50%' }}>{userData.name}</p>
                        </div>
                        <div className='profile-details'>
                            <p style={{ width: '50%' }}>Phone No. :</p>
                            <p style={{ width: '50%' }}>{userData.number}</p>
                        </div>
                        <div className='profile-details'>
                            <p style={{ width: '50%' }}>Email :</p>
                            <p style={{ width: '50%', overflow: 'hidden' }}>{userData.email}</p>
                        </div>
                        <div className='profile-details'>
                            <p style={{ width: '50%' }}>Address :</p>
                            <p style={{ width: '50%' }}>{userData.area},{userData.city}</p>
                        </div>
                    </div>
                    <div className="seperator-profile" />
                    <div className='transaction-container'>
                        <p className='transaction-heading'>TRANSACTIONS</p>
                        <div className='transaction-List'>
                            {TransactionListdata?.map((element, index) => {
                                return <TransactionComponent key={index} data={element} />;
                            })}
                        </div>
                    </div>
                </div>

            </div></>
    );
}
export default Profile;
