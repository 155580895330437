import logo from '../../assets/logo.png';
import '../Register/Register.css';
import '../../assets/fonts/Poppins-Bold.ttf';
import { useState } from 'react';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../firbase.config';
import { getDatabase, ref, onValue } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import Spinner from '../../Components/Loader';
import { loginUserData, savePhone } from '../../redux/loginSlice';
import { useDispatch } from 'react-redux';

function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const [valid, setvalid] = useState(false);
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch();

  const db = getDatabase();
  var md5 = require('md5');
  const navigate = useNavigate();

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value)
  }
  const handlePasswordChange = (e) => {
    let pwd = e.target.value
    if (pwd.length >= 6) {
      setvalid(true)
    }
    setPassword(e.target.value)
  }

  const LoginUser = () => {
    setLoader(true)
    if (!isNaN(phoneNumber)) {
      if (phoneNumber.startsWith("+91")) {
        alert("Please Enter Phone Number Without +91")
        setLoader(false)
      }
      else if (phoneNumber.length > 10 || phoneNumber.length < 10) {
        alert("Please Enter Proper Phone Number")
        setLoader(false)
      } else {
        // if (timeNow > tokenExp) {
        const starCountRef = ref(db, 'Users/+91' + phoneNumber + '/data/');
        onValue(starCountRef, (snapshot) => {
          const data = snapshot.val();
          if (data != null) {
            if (data.blocked === false) {
              if (data.role === "user") {
                signInWithEmailAndPassword(auth, data.email, md5(data?.city))
                  .then((userCredential) => {
                    const userDataString = JSON.stringify(userCredential.user);
                    localStorage.setItem('userToken', userDataString)
                    localStorage.setItem('userPhone', "+91" + phoneNumber)
                    dispatch(loginUserData(userDataString))
                    dispatch(savePhone("+91" + phoneNumber))

                    if (data.role === "user") {
                    navigate('/', { replace: true })
                    } else {
                      navigate('/dash', { replace: true })
                    }
                    //const user = userCredential.user;
                    // ...
                  })
                  .catch((error) => {
                    alert("Something went wrong please try again")
                    setLoader(false)
                  });
              } else {
                setshowPassword(true)
                setLoader(false)

              }
            } else {
              alert("This user is blocked, Please contact admin")
              setLoader(false)
            }
          } else {
            alert("User with phone number not registered")
            setLoader(false)
          }
        });
        // } else {
        //   alert("Session expired please login")
        //   setLoader(false)
        // }
      }
    } else {
      alert("Phone Number should contaion only numbers");
      setLoader(false)
    }
  }

  const LoginAdmin = () => {
    const starCountRef = ref(db, 'Users/+91' + phoneNumber + '/data/');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data != null) {
        if (data.blocked === false) {
          signInWithEmailAndPassword(auth, data.email, md5(password))
            .then((userCredential) => {
              const userDataString = JSON.stringify(userCredential.user);
              localStorage.setItem('userToken', userDataString)
              localStorage.setItem('userPhone', "+91" + phoneNumber)
              dispatch(loginUserData(userDataString))
              dispatch(savePhone("+91" + phoneNumber))

              navigate('/dash', { replace: true })

            })
            .catch((error) => {
              alert("Something went wrong please try again")
              setLoader(false)
            });

        } else {
          alert("This user is blocked, Please contact admin")
          setLoader(false)
        }
      } else {
        alert("User with phone number not registered")
        setLoader(false)
      }
    });
  }

  // const sendOtp = () => {
  //   try {
  //     const recaptcha = new RecaptchaVerifier("recaptcha-container", {}, auth)
  //     recaptcha.render();
  //     signInWithPhoneNumber(auth, phoneNumber, recaptcha)
  //       .then((confirmationResult) => {
  //         console.log("confirmationResult", confirmationResult);
  //         // SMS sent. Prompt user to type the code from the message, then sign the
  //         // user in with confirmationResult.confirm(code).
  //         window.confirmationResult = confirmationResult;
  //         recaptcha.clear();
  //         // ...
  //       }).catch((error) => {
  //         console.log("error", error);
  //         // Error; SMS not sent
  //         // ...
  //       });
  //   } catch (error) {
  //     console.log("error", error);
  //   }


  //   // console.log("sendoTp", recap);
  // }
  return (
    <div className="background" style={{ height: window.innerHeight }}>
      <Spinner visible={loader} />
      <div className="login-banner">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 60 }}>
          <div>
            <img src={logo} alt="" style={{ width: 80, height: 80, marginRight: 50 }} />
          </div>
          <div>
            <h3 className='logo-name'>GMB COMPLEX</h3>
          </div>
        </div>
        <div >
          <p className="name">
            GOWRI <br />
            PRASANNA <br />
            CONVENTION <br />
            HALL <br />
          </p>
        </div>
      </div>


      <div className="">
        <div className="row">
          <div className="col-lg-6">
            <div
              className="card bg-warning mb-3"
              style={{ width: 444, backgroundcolor: 'orange' }}
            >
              <div className="card-body">
                <div className="pt-4 pb-2">
                  <h5 className="card-title text-center pb-0 fs-4">SIGN IN</h5>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-25">
                      <label for="number">PH. NO:</label>
                    </div>
                    <div className="col-75">
                      <input
                        type="text"
                        id="number"
                        name="phonenumber"
                        placeholder="phonenumber"
                        onChange={(e) => handlePhoneChange(e)}
                        value={phoneNumber}
                      />
                    </div>
                  </div>
                </div>

                {showPassword && <div className="form-group">
                  <div className="row">
                    <div className="col-25">
                      <label for="password">Password:</label>
                    </div>
                    <div className="col-75">
                      <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="*********"
                        onChange={(e) => handlePasswordChange(e)}
                        value={password}
                      />
                    </div>
                  </div>
                </div>}

                <hr></hr>
                {!showPassword && <button className="button" onClick={LoginUser}>Login</button>}
                {showPassword && <button className="button" onClick={LoginAdmin}>Login</button>}
                <div>
                  <a href="/">Home</a>
                  {/* <a href="/register" style={{ marginLeft: 150 }}>
                    Register
                  </a> */}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
export default Login;
