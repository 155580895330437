import React from "react";
import "./CustomerBooking.css"
import { useSelector } from 'react-redux';
import { getUserPhone } from '../../redux/loginSlice';
import { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, update, remove } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import Spinner from "../../Components/Loader";
import PopupWithReason from "../../Components/ADCancelPopUp";
import Card from "../../Components/Card";
import generatePDF, { Options } from "react-to-pdf";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ManagerPopUp from "../../Components/ManagerPopUp";

const CustomerBooking = () => {
    const navigate = useNavigate();
    const count = useSelector(getUserPhone);
    const db = getDatabase();
    const [userData, setUserData] = useState([])
    const [loader, setLoader] = useState(true)
    const [isAdmin, setisAdmin] = useState(false)
    const [isManager, setisManager] = useState(false)
    const [TransactionListdata, setTransactionList] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);
    const [showCard, setShowCard] = useState(false);
    const [selectedDate, setSelectedDate] = useState();
    const [formData, setFormData] = useState();



    useEffect(() => {
        setLoader(true)
        const starCountRef = ref(db, 'Users/' + count + '/data/');
        onValue(starCountRef, (snapshot) => {
            const data1 = snapshot.val();
            if (snapshot.exists()) {
                // console.log("data1", data1.role);
                if (data1.role === "admin") {
                    setisAdmin(true)
                    setisManager(false)
                } else if (data1.role === "Manager") {
                    setisAdmin(false)
                    setisManager(true)
                } else {
                    setisManager(false)
                    setisAdmin(false)
                    navigate('/', { replace: true });
                }
                setUserData(data1)
                setLoader(false)
            }
        });

        const starCountRef1 = ref(db, 'CustomerBooking/');
        onValue(starCountRef1, (snapshot1) => {
            const data2 = snapshot1.val();
            if (snapshot1.exists()) {
                const TransactionList = Object.keys(data2).map(key => {
                    const booking = data2[key];
                    const dateTime = new Date(booking.bookedTime);
                    // Get the time in a readable format
                    const formattedTime = dateTime.toLocaleTimeString('en-US', { timeStyle: 'short' });
                    return {
                        date: booking.date,
                        bookedTime: booking.bookedTime,
                        hallType: booking.hallType,
                        amount: booking?.amount,
                        status: booking?.status === 'Pending' ? 'waiting for approval' : booking?.status,
                        // tnxNo: booking?.tnxNo,
                        // url: booking?.url,
                        number: booking?.number,
                        bookingId: booking?.bookingId,
                        time: formattedTime,
                        name: booking?.name,
                        area: booking?.area,
                        city: booking?.city,
                        managerConfirm: booking?.managerConfirm,
                        // email: booking?.email,
                        // approvedBy: booking?.approvedBy ?? "",
                        // rejectedBy: booking?.rejectedBy ?? "",
                        reason: booking?.reason,
                        balance: booking?.balance,
                        minAmount: booking?.minAmount,
                        // paidAmount: booking?.paidAmount,
                        gst: booking?.gst,
                        // paymentType: booking?.paymentType,
                        // paymentOption: booking?.paymentOption,
                        // cheque: booking?.cheque,
                        // tnxNo: booking?.tnxNo,
                        hallPrice: booking?.hallPrice,
                        functionType: booking?.functionType,
                        payTime: booking?.payTime
                        // recieptNo:booking?.recieptNo
                    };
                });
                setTransactionList(TransactionList)
                setSearchResults(TransactionList)
                setLoader(false)
            }
        });

    }, [db, count, navigate])

    // useEffect(() => {
    //     if (searchTerm.trim() !== '') {
    //         const results = Object.values(TransactionListdata).filter(
    //             booking => booking.number.includes(searchTerm)
    //         );
    //         setTransactionList(results);
    //     } else {
    //         setTransactionList(searchResults)
    //     }
    // }, [searchTerm, searchResults, TransactionListdata])

    // const getTargetElement = () => document.getElementById("DownloadContainer");

    // const downloadPdf = () => generatePDF(getTargetElement, options);



    const handleCancle = (data, reason) => {
        // setLoader(true)
        let newData = { ...data, status: "Cancel", reason: reason,balance:0,managerConfirm:false,payTime:""  }
        const updatesAdmin = {};
        const updates = {};
        // updatesAdmin['/CustomerBooking/' + data.bookingId] = newData;
        updates['/UserTransaction/' + data.number + "/" + data.bookingId + "/bookingdata/"] = newData;
        update(ref(db), updatesAdmin);
        update(ref(db), updates);
        remove(ref(db, "/bookedDates/" + data.bookingId));
        remove(ref(db, '/CustomerBooking/' + data.bookingId));

        // emailjs.send("service_in8ea8d", "template_4s07k29", {
        //     to_name: data?.name ?? "",
        //     message:
        //         `Sorry Booking declined\n` +
        //         `Booking Details\n` +
        //         `Booked Date: ${data.date}\n` +
        //         `Hall Type: ${data?.type}\n` +
        //         `Function: ${data?.function}\n` +
        //         `Amount: ${data?.amount}\n` +
        //         `paidAmount:${data?.Amt}\n` +
        //         `balance: ${data?.balance}\n` +
        //         `paymentType: ${data?.Optionvalue}\n` +
        //         `paymentOption: ${data?.dropvalue}\n` +
        //         `${data?.Optionvalue === "Cheque" ? `cheque:` + data?.cheque : ``}\n` +
        //         `${data?.Optionvalue !== "Cheque" ? `tnxNo:` + data?.tnx : ``}\n` +
        //         `DeclinedBy : GMBComplex Customer service\n` +
        //         `Reason : ${reason}\n` +
        //         `Status: Declined\n\n` +

        //         `Agreed T&C\n` +
        //         `We are doing the function under the rules and regulations of the Government.\n` +
        //         `I will Cooperate with the convention hall crew members.\n` +
        //         `I the customer not use of loud sound devices inside the convention.\n` +
        //         `I will not use plastic items.\n` +
        //         `Drinking water responsibility is taken by the customer only.\n` +
        //         `Items should be given or taken by counting.\n` +
        //         `I the customer will pay the electric meter reading and the gas meter or gas cylinder used by the customer, Amount to be taken in advance.\n` +
        //         `In case the customer damages the property, chairs, and kitchen items while the function is going on should be fulfilled by the customer.\n` +
        //         `I will carry my own key locks for the rooms, management is not responsible for money jewellery and expensive things.\n` +
        //         `Amount is not refunded after the booking.\n` +
        //         `Use of convention hall time is morning 7:00 AM to Evening 5:00 PM only.\n` +
        //         `Prior day use of the convention hall and kitchen is not allowed.\n` +
        //         `The convention hall should be used on the date of booking only.\n` +
        //         `I will not use non-vegetarian only vegetarian will use.\n\n` +

        //         `Any queries Contact\n` +
        //         `Rudresh S (Manager): +919008806484\n` +
        //         `Kanthraju: +919740570582`,
        //     to_email: data?.email,
        // }, {
        //     publicKey: 'eQNt8Ss8o_RZ6S-pi',
        // }).then(
        //     () => {
        //         alert("Booking Details declined")
        //         setLoader(false)
        //     },
        //     (error) => {
        //         console.log('FAILED...', error);
        //         setLoader(false)
        //     },
        // );
        // const postData = {
        //     "messaging_product": "whatsapp",
        //     "recipient_type": "individual",
        //     "to": "+91" + data.number,
        //     "type": "template",
        //     "template": {
        //         "name": "booking_confirmations",
        //         "language": {
        //             "code": "en_US"
        //         },
        //         "components": [
        //             {
        //                 "type": "body",
        //                 "parameters": [
        //                     {
        //                         "type": "text",
        //                         "text": userData?.role === "user" ? userData?.name : data?.AdminData?.name
        //                     },
        //                     {
        //                         "type": "text",
        //                         "text": data.date
        //                     },
        //                     {
        //                         "type": "text",
        //                         "text": data?.type
        //                     },
        //                     {
        //                         "type": "text",
        //                         "text": data?.function
        //                     },
        //                     {
        //                         "type": "text",
        //                         "text": data?.Amt
        //                     },
        //                     {
        //                         "type": "text",
        //                         "text": data.balance
        //                     },
        //                     {
        //                         "type": "text",
        //                         "text": data.Optionvalue
        //                     },
        //                     {
        //                         "type": "text",
        //                         "text": data.dropvalue
        //                     },
        //                     {
        //                         "type": "text",
        //                         "text": "Booking Declined"
        //                     }
        //                 ]
        //             }
        //         ]
        //     }
        // }
        // fetch('https://graph.facebook.com/v18.0/235953449612032/messages', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + process.env.REACT_APP_WAP_TOKEN
        //     },
        //     body: JSON.stringify(postData),
        // })
        //     .then(response => {
        //         if (!response.ok) {
        //             throw new Error('Network response was not ok');
        //         }
        //         return response.json();
        //     })
        //     .then(data => {
        //         // console.log('Success:', data);
        //         // Handle successful response data
        //         alert("Booking Details and status sent to registered number")
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //         // Handle error
        //     });
    }

    const handleConfirmSubmit = (data) => {
        setLoader(true)
        let newData = { ...data, managerConfirm:true, reason:"",payTime:new Date().toString() }
        const updatesAdmin = {};
        const updateCustomer = {};
        const updates = {};
        updatesAdmin['/AdminTransactions/' + data.bookingId] = newData;
        updateCustomer['/CustomerBooking/' + data.bookingId] = newData;
        updates['/UserTransaction/' + data.number + "/" + data.bookingId + "/bookingdata/"] = newData;
        update(ref(db), updatesAdmin);
        update(ref(db), updateCustomer);
        update(ref(db), updates);
    }

    const handleOpenPopup = (data) => {
        setSubmittedData(data)
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setIsConfirmPopupOpen(false)
    };

    const handleSubmitReason = (reason) => {
        // setCancellationReason(reason);
        handleCancle(submittedData, reason);
    };

    const handlesubmitForm = (data) => {
        // setCancellationReason(reason);
        handleConfirmSubmit(data);
    };

    const handleTnxChange = (e) => {
        setSearchTerm(e.target.value);
    }

    const handleDownload = (data) => {
        // console.log("",data);
        setSubmittedData(data)
        setShowCard(true);
        // downloadPdf();
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-IN', options);
    };

    const handleSearch = () => {
        if (selectedDate !== '' && selectedDate !== null) {
            const formatedDate = formatDate(selectedDate);
            const results = Object.values(searchResults).filter(
                booking => booking.date === formatedDate
            );
            // console.log("re", results);
            setTransactionList(results);
        } else {
            setTransactionList(searchResults)
        }

    }

    const handleClear = () => {
        setSelectedDate(null)
        setTransactionList(searchResults)
    }

    const handleUpdate = (data) => {
        setSubmittedData(data)
        setIsConfirmPopupOpen(true)
    }


    const TransactionComponent = (data) => {
        // console.log(data);
        const disableBtn =  data?.data?.managerConfirm ;
        return (
            <>
                <div className='horizontal-seprator-ad' />
                <div className='transaction-containerlist-ad'>
                    <div className='tranaction-details-ad'>
                        <p style={{ fontSize: 18, fontWeight: 'bold' }}>{data?.data.date}</p>
                        <p style={{ fontWeight: 'bold' }}>{data?.data.hallType}</p>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                            <p> Name : </p><p style={{ fontWeight: 'bold' }}> {data?.data.name}</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                            <p>Function Type: <b>{data?.data?.functionType}</b></p>
                        </div>
                        {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                            <p>Payment Option: <b>{data?.data?.paymentOption}</b></p>
                        </div> */}
                        {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                            {data?.data?.tnxNo ? (<><p>Tnx No: <b>{data?.data.tnxNo}</b></p></>) : <></>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                            {data?.data?.cheque ? (<><p>Cheque No: <b>{data?.data.cheque}</b></p></>) : <></>}
                        </div> */}
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                            <p>Ph No: <b>{data?.data.number}</b></p>
                        </div>
                        {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                            {data?.data?.approvedBy ? (<><p>ApprovedBy: <b>{data?.data?.approvedBy}</b></p></>) : <></>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                            {data?.data?.rejectedBy ? (<><p>RejectedBy: <b> {data?.data?.rejectedBy}</b></p></>) : <></>}
                        </div> */}
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4 }}>
                            {data?.data?.reason ? (<><p>Reason: <b> {data?.data?.reason}</b></p></>) : <></>}
                        </div>



                    </div>
                    {/* <div className='status-container-ad'>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', lineHeight: 1, color: 'black' }}>
                            <div>{"Status : "}</div>
                            <p style={{ color: data.data.status === "Confirmed" ? "green" : data.data.status === "Cancel" ? "red" : "orange", fontWeight: 'bold' }}>{" "}{data?.data.status}</p>
                        </div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <p onClick={() => handleDownload(data.data)}>Download Reciept</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {data?.data.url !== "" ? <a href={data?.data.url} target='blank'>View Receipt</a> : <></>}
                            </div>
                        </div>

                    </div> */}
                    <div className='status-container'>
                        <div>
                            {(isManager || isAdmin) && <button className="bookNow-ad" style={{ opacity: disableBtn ? 0.5 : 1 }} onClick={() => handleUpdate(data.data)} disabled={disableBtn}>{"Confirm"}</button>}
                            {(isManager || isAdmin) && <button className="cancle-book" onClick={() => handleOpenPopup(data.data)}>{"Cancel"}</button>}
                            <p style={{ color: "black", fontSize: 12 }}>BookedTime - {data?.data.time}</p>
                        </div>

                    </div>
                </div></>
        );
    };
    return (
        <div>
            <Spinner visible={loader} />
            <PopupWithReason isOpen={isPopupOpen} onClose={handleClosePopup} onSubmit={handleSubmitReason} />
            <ManagerPopUp isOpen={isConfirmPopupOpen} onClose={handleClosePopup} onSubmit={handlesubmitForm} Details={submittedData} />
            {/* <Card title="Receipt" data={submittedData} isOpen={showCard}
                onClose={() => setShowCard(false)} isAdmin={isAdmin} recNum={""}/> */}
            <div style={{ backgroundColor: 'white', width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
                <p
                    style={{
                        display: 'flex',
                        marginBlock: 0,
                        marginInline: 0,
                        color: 'Black',
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                        fontSize: 40,
                        fontFamily: 'MainHeading',
                        textAlign: 'center',
                    }}
                >
                    CUSTOMER BOOKINGS
                </p>
            </div>
            <div className="searchbar">
                {/* <label >Search</label> */}
                {/* </div>
                        <div className="row-75"> */}
                {/* <input
                    style={{ borderRadius: 8, borderColor: 'transparent', marginLeft: 10, backgroundColor: 'lightgrey', width: 350, height: 40 }}
                    type="text"
                    onChange={(e) => handleTnxChange(e)}
                    value={searchTerm}
                    placeholder="Search Phone Number"
                /> */}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <DatePicker
                        selected={selectedDate}
                        placeholderText="Date"
                        onChange={date => setSelectedDate(date)}
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        style={{ marginBottom: '20px', marginLeft: '20px' }}
                    />
                    <button onClick={handleSearch} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', marginLeft: 30 }}>Search</button>
                    <button onClick={handleClear} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', marginLeft: 30 }}>Clear</button>
                </div>
            </div>
            <div>
                <div className='transaction-List-ad'>
                    {TransactionListdata?.map((element, index) => {
                        return <TransactionComponent key={index} data={element} />;
                    })}
                </div>
                {/* <div id="DownloadContainer"> */}

                {/* </div> */}
            </div>
        </div>
    );
}

export default CustomerBooking;