import React, { useState, useEffect } from 'react';
import './AdPopUp.css';
import { getDatabase, ref, onValue } from "firebase/database";

const Popup = ({ isOpen, onClose, onSubmit, Details, isEdit = false }) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [area, setArea] = useState('');
    const [city, setCity] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const db = getDatabase();

    // Initialize form with existing details if isEdit is true
    useEffect(() => {
        if (isEdit && Details) {
            setName(Details.name || '');
            setPhone(Details.phone || '');
            setArea(Details.area || '');
            setCity(Details.city || '');
        }
    }, [isEdit, Details]);

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-IN', options);
    };

    const handleSubmit = () => {
        if (name.trim() === '' || phone.trim() === '' || area.trim() === '' || city.trim() === '') {
            setErrorMessage('All fields are required.');
            return;
        }

        const starCountRef = ref(db, 'UsersList/' + phone);
        onValue(starCountRef, (snapshot) => {
            const data = snapshot.val();
            if (snapshot.exists()) {
                if (data.blocked === true) {
                    setErrorMessage('Phone number is blocked, please contact admin');
                    return;
                }
            }

            if (!isNaN(phone)) {
                if (phone.startsWith("+91")) {
                    alert("Please enter phone number without +91");
                } else if (phone.length !== 10) {
                    alert("Please enter a proper 10-digit phone number");
                } else {
                    onSubmit({ name, phone, area, city });
                    setName('');
                    setArea('');
                    setPhone('');
                    setCity('');
                    setErrorMessage('');
                    onClose();
                }
            } else {
                alert("Please enter a proper phone number");
            }
        });
    };

    return (
        <div className={`popup ${isOpen ? 'open' : ''}`}>
            <div className="popup-content">
                <h2>{isEdit ? 'Edit Details' : 'Enter Details'}</h2>
                <h6>Name*</h6>
                <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <h6>Phone Number*</h6>
                <input
                    type="tel"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <h6>Selected Date</h6>
                <input
                    type="text"
                    placeholder="Date"
                    value={formatDate(Details.date)}
                    disabled={true}
                />
                <h6>Hall Type</h6>
                <input
                    type="text"
                    placeholder="Hall type"
                    value={Details.type}
                    disabled={true}
                />
                <h6>Area*</h6>
                <input
                    type="text"
                    placeholder="Area"
                    value={area}
                    onChange={(e) => setArea(e.target.value)}
                />
                <h6>City*</h6>
                <input
                    type="text"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                />
                {errorMessage && <p className="error">{errorMessage}</p>}
                <button onClick={handleSubmit}>
                    {isEdit ? 'Update' : 'Continue'}
                </button>
                <button onClick={onClose}>Cancel</button>
            </div>
        </div>
    );
};

export default Popup;
