import React, { useEffect, useState } from 'react';
import './AdPopUp.css';

const ManagerPopUp = ({ isOpen, onClose, onSubmit, Details }) => {
    const [name, setName] = useState(Details?.name);
    const [phone, setPhone] = useState(Details?.number);
    // const [area, setArea] = useState('')
    const [minAmount, setminAmount] = useState(Details?.minAmount);
    const [errorMessage, setErrorMessage] = useState('');
    const [dropvalue, DropsetValue] = React.useState('');
    const [Amt, setAmt] = useState("");
    const [AmtEditable, setAmtEditable] = useState(true)

    useEffect(() => {
        setName(Details?.name);
        setPhone(Details?.number);
        setminAmount(Details?.minAmount)
    })

    // const formatDate = (dateString) => {
    //     const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    //     const date = new Date(dateString);
    //     return date.toLocaleDateString('en-IN', options);
    // };

    const handleSubmit = () => {
        if ( dropvalue === '' ) {
            setErrorMessage("Select payment type")
            return;
        }else{

        }
        if (Amt?.replace('/-', '') < Details?.minAmount?.replace('/-', '')) {
            setErrorMessage("Amount cannot be less than advance amount")
            // setLoader(false)
        } else if (Amt?.replace('/-', '') > Details?.amount?.replace('/-', '')) {
            setErrorMessage("Amount cannot be greater than booking amount")
            // setLoader(false)
        } else {
            let balance = dropvalue === "Advance" ? (parseInt(Details?.amount?.replace('/-', '')) - parseInt(Amt?.replace('/-', ''))) : 0
            onSubmit({ ...Details, paymentType: dropvalue, paidAmount: Amt+"/-", balance:balance+"/-" });
            setName('');
            // setArea('');
            setPhone('');
            setAmt('');
            setErrorMessage('');
            DropsetValue('')
            setAmtEditable(true)
            onClose();
        }

    };

    const handleClose = () => {
        setName('');
        // setArea('');
        setPhone('');
        setAmt('');
        DropsetValue('')
        setErrorMessage('');
        setAmtEditable(true)
        onClose()
    }

    const getPrice = () => {
        if (Details?.hallType === "AC Hall") {
            return Details?.amount;
        }
        return Details?.hallPrice;
    };

    const handleTypeChange = (event) => {
        if (event.target.value === "Full Payment") {
            setAmt(Details?.amount.replace('/-', ''))
            setAmtEditable(true)
        } else {
            setAmt('')
            setAmtEditable(false)
        }
        DropsetValue(event.target.value);
    };

    const PaymentType = [
        { label: '--Select--', value: 'Null' },
        { label: 'Full Payment', value: 'Full Payment' },
        { label: 'Advance', value: 'Advance' },
    ];

    return (
        <div className={`popup ${isOpen ? 'open' : ''}`}>
            <div className="popup-content">
                <h2>Enter Details</h2>
                <h6>Name*</h6>
                <input
                    type="textPopup"
                    placeholder="Name"
                    value={name}
                    disabled={true}
                    onChange={(e) => setName(e.target.value)}
                />
                <h6>Phone Number*</h6>
                <input
                    type="tel"
                    placeholder="Phone Number"
                    value={phone}
                    disabled={true}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <h6>Selected Date</h6>
                <input
                    type="textPopup"
                    placeholder="Date"
                    value={Details?.date}
                    disabled={true}
                />
                <h6>Hall Type</h6>
                <input
                    type="textPopup"
                    placeholder="Hall type"
                    value={Details?.hallType}
                    disabled={true}
                />
                <h6>Payment Type*</h6>
                <select type="textPopup" value={dropvalue} onChange={handleTypeChange}>
                    {PaymentType.map((option) => (
                        <option value={option.value}>{option.label}</option>
                    ))}
                </select>
                <h6>{!AmtEditable ? "Minimun amount Rs."+minAmount : "Amount*"}</h6>
                <input
                    type="textPopup"
                    placeholder="Amount"
                    value={Amt}
                    onChange={(e) => setAmt(e.target.value?.replace("/-",''))}
                />
                {errorMessage && <p className="error">{errorMessage}</p>}
                <button onClick={handleSubmit}>Continue</button>
                <button onClick={handleClose}>Cancel</button>
            </div>
        </div>
    );
};

export default ManagerPopUp;
