import React, { useEffect, useState } from 'react';
import './card.css';
import logo from '../assets/logo.png'
import { IoClose } from "react-icons/io5";
import { getDatabase, ref, onValue, update, remove } from "firebase/database";

import generatePDF, { Options } from "react-to-pdf";

interface CardProps {
  title?: string;
  data?: any;
  isOpen?: boolean;
  onClose?: () => void;
  isAdmin: boolean;
  // recNum?: string
}



const Card: React.FC<CardProps> = ({ title = '', data, isOpen, onClose, isAdmin }: CardProps) => {
  // const recNum = data?.recieptNo
  const [recieptNo, setRecieptNo] = useState();
  const [getData, setData] = useState();
  const [updateEnable, setUpdateEnable] = useState(false)
  const db = getDatabase();
  useEffect(() => {
    setUpdateEnable(false)
    setData(data);
    setRecieptNo(data?.recieptNo ?? "")
  }, [isOpen]);
  if (!isOpen) return null
  const options: Options = {
    filename: data?.bookingId,
    page: {
      margin: 20
    }
  };

  const getTargetElement = () => document.getElementById("DownloadContainer");
  const downloadPdf = () => {
    
    generatePDF(getTargetElement, options);
  }
  const updateReciept = () =>{
    let newData = { ...getData, recieptNo: recieptNo ?? "", reason: getData?.reason ?? "" }

    const updatesAdmin = {};
    const updates = {};
    const updateDate = {};
    updatesAdmin['/AdminTransactions/' + getData.bookingId] = newData;
    updates['/UserTransaction/' + getData.number + "/" + getData.bookingId + "/bookingdata/"] = newData;
    updateDate["/finalReciepts/" + getData.bookingId] = {
      Id: getData?.bookingId,
      recieptNo: recieptNo ?? ""
    };
    update(ref(db), updatesAdmin);
    update(ref(db), updates);
    update(ref(db), updateDate);
    setUpdateEnable(false)
  }
  const dateObject = new Date(getData?.payTime);

  const handleNumberChange = (e) => {
    setRecieptNo(e.target.value)
    if(recieptNo !== e.target.value){
      setUpdateEnable(true)
    }else{
      setUpdateEnable(false)
    }
  }

  const handleChange = (fieldName, value) => {
    setData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setUpdateEnable(true)
  };

  const formattedDateTime = dateObject.toLocaleString('en-IN', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
  return (
    <div className="card-popup" >
      <div className="card-container" >
        <div id="DownloadContainer">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={logo} alt="" style={{ width: 80, height: 80, }} />
              <h3 style={{ color: 'black' }}>Gowri Prasanna Convenction hall <br /><h4>GMB COMPLEX</h4></h3>

            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <IoClose style={{
                marginBottom: 60,
                height: 20,
                width: 20
              }} onClick={() => {
                if (onClose) onClose();
                setRecieptNo('');
              }} />
               <p style={{ display: 'flex', justifyContent: 'end', marginRight: 20, marginTop: -32 }}>
                {"Reciept No. : "}
                <b><input
                  style={{ borderColor: 'transparent', width: 100, marginTop: -6, borderBottomColor: 'black' }}
                  type="number"
                  // disabled={isAdmin}
                  onChange={(e) => handleNumberChange(e)}
                  value={recieptNo}
                /></b></p>
            </div>
            <br /><p><b>GST. No. - 29AAFHG6637JIZV </b></p>

          </div>

          <h2 className="card-title">{title} {getData?.balance === "0/-" ? "" : "(Advance)"}</h2>
          <div className="table-container">
            <table className="card-table">
              <tbody>
                <tr>
                  <th>Hall Booked Date</th>
                  <td>{getData?.date}</td>
                </tr>
                <tr>
                  <th>Payment DateTime</th>
                  <td>{formattedDateTime}</td>
                </tr>
                <tr>
                  <th>Hall Type</th>
                  <td>{getData?.hallType}</td>
                </tr>
                <tr>
                  <th>Function</th>
                  <td>{getData?.functionType}</td>
                </tr>
                <tr>
                  <th>Amount</th>
                  {getData?.balance === "0/-" ? (
                    <>
                      <td>
                      ₹ <input type="recieptText" disabled={!isAdmin} value={getData?.hallPrice.replace('/-','')} onChange={(e) => handleChange('hallPrice', e.target.value)} /> Hall Price<br />
                      ₹ <input type="recieptText" disabled={!isAdmin} value={getData?.gst.replace('/-','')} onChange={(e) => handleChange('gst', e.target.value)} /> GST<br />
                      ₹ <input type="recieptText" disabled={!isAdmin} value={getData?.amount.replace('/-','')} onChange={(e) => handleChange('amount', e.target.value)} /> Total
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                      ₹ <input type="recieptText" disabled={!isAdmin} value={getData?.amount.replace('/-','')} onChange={(e) => handleChange('amount', e.target.value)} /> Total<br />
                      ₹ <input type="recieptText" disabled={!isAdmin} value={getData?.paidAmount.replace('/-','')} onChange={(e) => handleChange('paidAmount', e.target.value)} /> paid<br />
                      ₹ <input type="recieptText" disabled={!isAdmin} value={getData?.balance.replace('/-','')} onChange={(e) => handleChange('balance', e.target.value)} /> Balance
                      </td>
                    </>
                  )}

                </tr>
                <tr>
                  <th>Payment Type</th>
                  <td>{getData?.paymentType}</td>
                </tr>
                {/* <tr>
                  <th>Payment Option</th>
                  <td>{getData?.paymentOption}</td>
                </tr> */}
                {/* {getData?.tnxNo !== "" ?
                  <tr>
                    <th>Transaction Number</th>
                    <td>{getData?.tnxNo}</td>
                  </tr> : <></>}
                {getData?.cheque !== "" ?
                  <tr>
                    <th>Cheque Number</th>
                    <td>{getData?.cheque}</td>
                  </tr> : <></>} */}
                <tr>
                  <th>Status</th>
                  <td>{getData?.status}</td>
                </tr>
                {getData?.reason ?
                  <tr>
                    <th>Reason</th>
                    <td>{getData?.reason}</td>
                  </tr> : <></>}

                <tr>
                  <th>Phone Number</th>
                  <td>{getData?.number}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{getData?.name}</td>
                </tr>
                {/* <tr>
                  <th>Email</th>
                  <td>{getData?.email}</td>
                </tr> */}
              </tbody>
            </table>
            <div className="terms-container">
              <p className="terms-text">
                <b>Agreed T&amp;C</b><br />
                We are doing the function under the rules and regulations of the Government.<br />
                I will Cooperate with the convention hall crew members.<br />
                I the customer not use of loud sound devices inside the convention.<br />
                I will not use plastic items.<br />
                Drinking water responsibility is taken by the customer only.<br />
                Items should be given or taken by counting.<br />
                I the customer will pay the electric meter reading and the gas meter or gas cylinder used by the customer, Amount to be taken in advance.<br />
                In case the customer damages the property, chairs, and kitchen items while the function is going on should be fulfilled by the customer.<br />
                I will carry my own key locks for the rooms, management is not responsible for money jewellery and expensive things.<br />
                Amount is not refunded after the booking.<br />
                Use of convention hall time is morning 7:00 AM to Evening 5:00 PM only.<br />
                Prior day use of the convention hall and kitchen is not allowed.<br />
                The convention hall should be used on the date of booking only.<br />
                I will not use non-vegetarian only vegetarian will use.\n<br />
                <br />
                <b>Any queries Contact</b><br />
                Rudresh S (Manager): +919008806484<br />
                Kanthraju: +919740570582</p>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
          <button onClick={() => {
            if (onClose) onClose();
            setRecieptNo('');
          }} className="close-btn">Close</button>
          <button disabled={!updateEnable} onClick={updateReciept} style={{ marginLeft: 20, opacity: !updateEnable ? 0.5 : 1 }} className="close-btn">Update</button>
          <button disabled={updateEnable} onClick={downloadPdf} style={{ marginLeft: 20, opacity: updateEnable ? 0.5 : 1 }} className="close-btn">Download</button>
        </div>
      </div>
    </div>
  );
};

export default Card;
