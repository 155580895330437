import React from 'react';
import '../App.css';
import SlidingBanner from '../Components/SlidingBanner';
import HeaderTop from '../Components/header';
import Sidebar from '../Components/Sidebar';
import MyCalender from './CheckAvailablity/Availability';
import Services from './Services/Services';

function Index() {
  return (
    <div className="App">
      <Sidebar />
      <div style={{ paddingLeft: 80 }}>
        <HeaderTop />
        <SlidingBanner />
        <MyCalender />
        <Services />
      </div>
    </div>
  );
}

export default Index;
