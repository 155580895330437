import React, { useEffect, useState } from 'react';
import {
    FaTh,
} from "react-icons/fa";
import { GrTransaction, GrUpdate } from "react-icons/gr";
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png';
import { useTranslation } from "react-i18next";
import { TbLogout, TbBookUpload  } from "react-icons/tb";
import { signOut } from "firebase/auth";
import { auth } from '../firbase.config';
import { useDispatch } from 'react-redux';
import { loginUserData, savePhone } from '../redux/loginSlice';
import { getUserPhone } from '../redux/loginSlice';
import { useSelector } from 'react-redux';
import { getDatabase, ref, onValue} from "firebase/database";

const AdminSidebar = ({ children }) => {
    const dispatch = useDispatch();
    const db = getDatabase();
    const { t } = useTranslation();
    const count = useSelector(getUserPhone);
    const [userData, setUserData] = useState([])
    const [loader, setLoader] = useState(true)
    const [isAdmin, setisAdmin] = useState(false)
    
    useEffect(()=>{
        const starCountRef = ref(db, 'Users/' + count + '/data/');
        onValue(starCountRef, (snapshot) => {
            const data1 = snapshot.val();
            if (snapshot.exists()) {
                if (data1.role === "admin") {
                    setisAdmin(true)
                } else {
                    setisAdmin(false)
                }
                setUserData(data1)
                setLoader(false)
            }
        });
    },[count])
    const menuItem = [
        {
            path: "/dash",
            name: t('Home'),
            icon: <FaTh />,
            isAdmin: true
        },
        {
            path: "/Adtransactions",
            name: t('Transactions'),
            icon: <GrTransaction />,
            isAdmin: true
        },
        {
            path: "/updates",
            name: "Updates",
            icon: <GrUpdate />,
            isAdmin: isAdmin
        },
        {
            path: "/customerBooking",
            name: "CustomerBooking",
            icon: <TbBookUpload  />,
            isAdmin: true
        },
    ]

    const Logout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            localStorage.removeItem('userToken')
            localStorage.removeItem('userToken')
            dispatch(loginUserData(null))
            dispatch(savePhone(null))
            
        }).catch((error) => {
            // An error happened.
            // console.log("trye".error);
        });
    }
    return (
        <div className="SideNavcontainer">
            <div className="sidebar">
                <div className="top_section">
                    <img src={logo} style={{ height: 70, display: "block" }} alt="" />
                    <h1 style={{ display: "block" }} className="logo">{t('GMB_Complex')}</h1>
                    
                </div>
                {
                    menuItem.map((item, index) => (
                       (item.isAdmin) && <NavLink to={item.path} key={index} className="link" activeclassName="active">
                            <div className="icon">{item.icon}</div>
                            <div title={item.name} style={{ display: "block" }} className="link_text">{item.name}</div>
                        </NavLink>
                    ))
                }
                <div className="link" activeclassName="active" onClick={Logout}>
                    <TbLogout style={{ height: 30, width: 30 }} />
                    <div className="link_text">Logout</div>
                </div>
            </div>

            <main>{children}</main>
        </div>
    );
};

export default AdminSidebar;