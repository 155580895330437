import React, { useState } from 'react';
import './AdPopUp.css';

const PopupWithReason = ({ isOpen, onClose, onSubmit }) => {
    const [reason, setReason] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = () => {
        if (reason.trim() === '') {
            setErrorMessage('Please provide a reason for cancellation.');
            return;
        }

        onSubmit(reason);
        setReason('');
        setErrorMessage('');
        onClose();
    };

    return (
        <div className={`popup ${isOpen ? 'open' : ''}`}>
            <div className="popup-content">
                <h2>Reason for Cancellation</h2>
                <textarea
                    placeholder="Enter reason for cancellation"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                />
                {errorMessage && <p className="error">{errorMessage}</p>}
                <button onClick={handleSubmit}>Submit</button>
                <button onClick={onClose}>Cancel</button>
            </div>
        </div>
    );
};

export default PopupWithReason;
