import React, { useState } from 'react';
import './AdPopUp.css';
import { useTranslation } from "react-i18next";

const ManagerAlert = ({ isOpen, onClose }) => {
    const { t } = useTranslation();

    return (
        <div className={`popup ${isOpen ? 'open' : ''}`}>
            <div className="popup-content" style={{textAlign:'center'}}>
                <h3><b>{t('ThanksChoosing')}</b></h3>
                <label className='details'>{t('ContactManager')}</label>
                <label className='details'><b>{t('or')}</b></label>
                <label className='details'>{t('ConatactComplex')} :<a href="https://maps.app.goo.gl/7CDFGiXxGYYPSGMu7">Location</a></label>
            <button onClick={onClose}>OK</button>
            </div>
        </div>
    );
};

export default ManagerAlert;
