import React, {useEffect} from 'react';
import logo from '../assets/logo.png';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { loginUserData, savePhone } from '../redux/loginSlice';
function HeaderTop() {
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 738px)' });

  const { t, i18n } = useTranslation();
  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value
    i18n.changeLanguage(languageValue);
  }

  useEffect(()=>{
    localStorage.removeItem('userToken')
    localStorage.removeItem('userToken')
    dispatch(loginUserData(null))
    dispatch(savePhone(null))
  })
  

  return (
    <div
      style={{ marginTop: 0, paddingTop: 0, backgroundColor: 'transparent' }}
    >
      <div style={{
        display: 'flex',
        width: '100%', 
        justifyContent: 'flex-end', 
        flexDirection:'row',
        paddingRight: 40, 
        paddingTop:20,
        fontFamily: 'MainHeading', 
        color: 'black', 
        fontSize: 20
      }}>
        <button className='btn btn-primary' style={{marginRight:10}} value="ka" onClick={changeLanguageHandler }>{'Kannada'}</button>
        <button className='btn btn-primary' value="en" onClick={changeLanguageHandler }>{'English'}</button>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: 180,
          resize: 'inherit',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          flexDirection: 'row',
        }}
      >
        <div>
          <img
            src={logo}
            style={{ height: isTabletOrMobile ? 60 : 'auto' }}
            alt=""
          />
        </div>
        <div style={{ marginRight: isTabletOrMobile ? 30 : 0 }}>
          <p
            style={{
              display: 'flex',
              marginBlock: 0,
              marginInline: 0,
              color: 'white',
              fontWeight: 'bold',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              fontSize: isTabletOrMobile ? 20 : 60,
              fontFamily: 'MainHeading',
              textAlign: 'center',
            }}
          >
           {t('Home_heading')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeaderTop;
