import React, { useEffect, useState } from "react";
import './ContactManager.css';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from "../../Components/Loader";
import ManagerAlert from "../../Components/ManagerAlert";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { getUserPhone } from "../../redux/loginSlice";
import { useSelector } from 'react-redux';
import Popup from "../../Components/ADpopUp";

const ContactManager = () => {
    const [loader, setLoader] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [editPopUp, setEditPopup] = useState(false)
    const [userData, setUserData] = useState([])
    const count = useSelector(getUserPhone);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { state } = useLocation()
    let { data } = state;
    const [bookdata, setbookdata] = useState(state)
    const db = getDatabase();

    useEffect(() => {
        // console.log("---->",bookdata);
        if (bookdata?.data !== null && bookdata?.data !== undefined && bookdata?.data !== {}) {
            setLoader(false)
        } else {
            navigate("/")
        }
    }, [loader, bookdata])

    useEffect(() => {
        setLoader(true)
        if (count) {
            const starCountRef = ref(db, 'Users/' + count + '/data/');
            onValue(starCountRef, (snapshot) => {
                const data1 = snapshot.val();
                if (snapshot.exists()) {
                    setUserData(data1)
                    setLoader(false)
                }
            });
            setLoader(false)
        }
    }, [db, count])

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-IN', options);
    };

    const getPrice = () => {
        if (bookdata?.data?.type === "AC Hall") {
            return bookdata?.data?.price.acTotal;
        }
        return bookdata?.data?.price.NonAcTotal
    };

    const getHallPrice = () => {
        if (bookdata?.data?.type === "AC Hall") {
            return bookdata?.data?.price.Ac;
        }
        return bookdata?.data?.price.Non_Ac
    };

    const getGstPrice = () => {
        if (bookdata?.data?.type === "AC Hall") {
            return bookdata?.data?.price.Ac_Gst;
        }
        return bookdata?.data?.price.Non_Ac_Gst
    };

    const completeBooking = (e) => {
        setIsPopupOpen(true)
        setLoader(true)
            }

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        let formatedDate = formatDate(bookdata?.data?.date)
        let timeNow = new Date().getTime();
        let bookingdata = {
            bookingId: timeNow,
            date: formatedDate,
            hallType: bookdata?.data?.type,
            amount: getPrice(),
            gst: getGstPrice(),
            hallPrice: getHallPrice(),
            minAmount: bookdata?.data?.price?.Min_price,
            status: 'Pending',
            bookedTime: new Date().toString(),
            number: bookdata?.data?.AdminData?.phone,
            name: bookdata?.data?.AdminData?.name,
            functionType: bookdata?.data?.function,
            area: bookdata?.data?.AdminData?.area,
            city: bookdata?.data?.AdminData?.city
        }
        let phNumber = bookdata?.data?.AdminData?.phone;
        set(ref(db, 'UserTransaction/' + phNumber + "/" + timeNow), {
            bookingdata
        });
        set(ref(db, 'CustomerBooking/' + timeNow), {
            bookingId: timeNow,
            date: formatedDate,
            hallType: bookdata?.data?.type,
            amount: getPrice(),
            gst: getGstPrice(),
            hallPrice: getHallPrice(),
            minAmount: bookdata?.data?.price?.Min_price,
            status: 'Pending',
            bookedTime: new Date().toString(),
            number: bookdata?.data?.AdminData?.phone,
            name: bookdata?.data?.AdminData?.name,
            functionType: bookdata?.data?.function,
            area: bookdata?.data?.AdminData?.area,
            city: bookdata?.data?.AdminData?.city
        });
        set(ref(db, 'bookedDates/' + timeNow), {
            formatedDate,
            blocked: false,
            status: "pending"
        });
        set(ref(db, 'UsersList/' + bookdata?.data?.AdminData?.phone), {
            number: bookdata?.data?.AdminData?.phone,
            name: bookdata?.data?.AdminData?.name,
            functionType: bookdata?.data?.function,
            area: bookdata?.data?.AdminData?.area,
            city: bookdata?.data?.AdminData?.city,
            blocked: false
        });
        const postData = {
            "messaging_product": "whatsapp",
            "recipient_type": "individual",
            "to": "+919448082840",
            "type": "template",
            "template": {
                "name": "manager_update ",
                "language": {
                    "code": "en"
                },
                "components": [
                    {
                        "type": "body",
                        "parameters": [
                            {
                                "type": "text",
                                "text": bookdata?.data?.AdminData?.name
                            },
                            {
                                "type": "text",
                                "text": bookdata?.data?.AdminData?.phone
                            },
                            {
                                "type": "text",
                                "text": formatDate(bookdata?.data?.date)
                            },

                        ]
                    }
                ]
            }
        }

        fetch('https://graph.facebook.com/v18.0/235953449612032/messages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + process.env.REACT_APP_WAP_TOKEN
            },
            body: JSON.stringify(postData),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                localStorage.removeItem('booking');
                return response.json();
            })
            .then(data => {
                // console.log('Success:', data);
                // Handle successful response data

            })
            .catch(error => {
                console.error('Error:', error);
                // Handle error
            });

        // console.log("++",userData?.role);
        if (userData?.role && userData?.role !== undefined) {
            navigate("/dash", { replace: true })
        } else {
            navigate('/', { replace: true })
        }
        setLoader(false)
    };

    const handleEditPopupOpen = () => {
        setEditPopup(true);
    };
    const handleEditPopupClose = () => {
        setEditPopup(false);
    };

    const handleSubmit = (res) => {
        let userData = res;
        // console.log("usr", userData, {...data,AdminData:res});
        setbookdata({data:{...data,AdminData:res}})
        setLoader(false)
    };

    let engagementDetails = {};

    const styles = {
        button: {
            display: 'flex',
            alignItems: 'center',
            padding: '3px 10px',
            fontSize: '16px',
            cursor: 'pointer',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            alignSelf: 'end',
        }
    };

    return (
        <div className="pageload">
            <Spinner visible={loader} />
            <div className='header-container'>
                <p className='profile-heading'>{t('ConfirmDetails')}</p>
                <div>
                    <label className='header-detail-font'>{bookdata?.data?.type}<br />{t('BookingDate')} {formatDate(bookdata?.data?.date)}</label>
                </div>
                <p className='header-amount-font-pay'> ₹ {getPrice()} </p>
            </div>
            <div className="container_seprator" />
            <div style={{ display: 'flex', flexDirection: 'column', }}>
                <div className="listitems">
                    <table>
                        <tbody style={{ borderWidth: 0 }}>
                            <tr>
                                <td>Name</td>
                                <td>{bookdata?.data.AdminData.name}</td>
                                <td>
                                    <button style={styles.button} onClick={() => handleEditPopupOpen()}>
                                        ✏️ Edit
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>Phone No.</td>
                                <td>{bookdata?.data.AdminData.phone}</td>
                            </tr>
                            <tr>
                                <td>BookingDate</td>
                                <td>{formatDate(bookdata?.data?.date)}</td>
                            </tr>
                            <tr>
                                <td>Hall Type</td>
                                <td>{bookdata?.data?.type}</td>
                            </tr>
                            <tr>
                                <td>Price</td>
                                <td><b>{getPrice()}</b>  {t('including_GST')}</td>
                            </tr>
                            <tr>
                                <td>Function</td>
                                <td>{bookdata?.data?.function}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Popup isOpen={editPopUp} onClose={handleEditPopupClose} onSubmit={handleSubmit} Details={
                engagementDetails = {
                    phone: bookdata?.data?.AdminData?.phone,
                    name: bookdata?.data?.AdminData?.name,
                    date: bookdata?.data?.date,
                    price: getPrice(),
                    function: bookdata?.data?.function,
                    type: bookdata?.data?.type,
                    area: bookdata?.data?.AdminData?.area,
                    city: bookdata?.data?.AdminData?.city,
                }
            } isEdit = {true}/>
            <ManagerAlert isOpen={isPopupOpen} onClose={handleClosePopup} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: 20 }}>
                {/* <label className='details'>{t('ContactManager')}</label>
                <label className='details'><b>{t('or')}</b></label>
                <label className='details'>{t('ConatactComplex')} :<a href="https://maps.app.goo.gl/7CDFGiXxGYYPSGMu7">Location</a></label> */}
                <button className="proceed-pay" disabled={loader} style={{ marginTop: 30, marginBottom: 30 }} onClick={(e) => completeBooking(e)}>{t('Complete_book')}</button>
            </div>
        </div>
    )
}

export default ContactManager;