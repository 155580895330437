import React from 'react'
import {useSelector} from "react-redux"
import {Navigate, Outlet} from "react-router-dom"
import { getUser } from '../redux/loginSlice';

const ProtectedRoute = ({children}) => {
    const count = useSelector(getUser);
    const user = JSON.parse(count.user)
    const tokenExp = user?.stsTokenManager?.expirationTime
    const timeNow = new Date().getTime();
    return(
        timeNow < tokenExp ? <Outlet/> : <Navigate to="/login"/>
    )
};

export default ProtectedRoute;

// import { Outlet, Navigate } from 'react-router-dom'

// const ProtectedRoute = () => {
//     const count = useSelector(getUser);
//     console.log("protected->",JSON.parse(count.user));
    // return(
    //     count.user ? <Outlet/> : <Navigate to="/login"/>
    // )
// }

// export default ProtectedRoute