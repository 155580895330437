import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <nav>
      </nav>

      <Outlet />
    </>
  )
};
export default Layout;
