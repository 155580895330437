import React from 'react';
import './TermsAndCondition.css';
import noSmoke from '../../assets/noSmoke.png'
import noNonveg from '../../assets/noNonveg.png'
import noTobacco from '../../assets/noTobacco.png'
import noPlastic from '../../assets/noPlastic.png'
import noCards from '../../assets/nocards.png'
import noDrinks from '../../assets/noDrinks.png'
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { getDatabase, ref, onValue } from "firebase/database";

const TermsAndCondition = (props) => {
    const { t } = useTranslation();
    const { state } = useLocation()
    const { data } = state ?? {};
    const navigate = useNavigate();
    const db = getDatabase();
    const [getDisable, setDisable] = useState(false);
    const [disabledDates, setDisableDates] = React.useState([]);

    const location = useLocation();
    const previousLocation = useRef(location); // Store the previous location

    useEffect(() => {
        // console.log("location", previousLocation.current);
        const handleBackButtonClick = () => {
            // console.log('Back button clicked');
            // Custom logic here, e.g., navigate somewhere else
            // navigate('/some-page');
        };

        if (previousLocation.current !== location) {
            if (window.history.state && window.history.state.idx < previousLocation.current.state.idx) {
                handleBackButtonClick();
            }
        }

        previousLocation.current = location; // Update previous location on every render
    }, [location, navigate]);


    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-IN', options);
    };

    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }

    useEffect(() => {
        topFunction();
        if (data !== null && data !== undefined && data !== {} && data?.date !="") {
            setDisable(true)
        } else {
            navigate("/")
        }

        const starCountRef1 = ref(db, 'bookedDates/');
        onValue(starCountRef1, (snapshot) => {
            const data1 = snapshot.val();
            if (snapshot.exists()) {
                const bDates = Object.values(data1).map(entry => {
                    const parts = entry.formatedDate.split('/');
                    const formattedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
                    return {
                        date: new Date(formattedDateString),
                        status: entry.status
                    };
                });
                setDisableDates(bDates)

            }
        });
    }, [data, navigate])

    useEffect(()=>{
        const userData=localStorage.getItem('booking');
        // console.log("-->",userData);
        if (userData !== null) {
            // Select all checkboxes
            const checkboxes = document.querySelectorAll('input[type="checkbox"]');
            
            // Loop through each checkbox and set it to checked
            checkboxes.forEach((checkbox) => {
                checkbox.checked = true;
            });
        }
    },[data])

    const getPrice = () => {
        if (data?.type === "AC Hall") {
            return data?.price.acTotal;
        }
        return data?.price.NonAcTotal
    };
    const verifyCheckboxes = () => {
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        let allChecked = true;

        checkboxes.forEach((checkbox) => {
            if (!checkbox.checked) {
                allChecked = false;
            }
        });

        return allChecked;
    };

    const handleClick = () => {
        const allCheckboxesChecked = verifyCheckboxes();
        if (allCheckboxesChecked) {
            navigate("/completeBooking", { state: { data: data } })
            localStorage.setItem('booking', data);
        } else {
            alert('Please check and agree to all Terms and conditions')
        }
    }

    return (
        <div className="background-Tc" >
            <div className='header-container'>
                <p className='profile-heading'>{t('BOOKING_DETAILS')}</p>
                <div>
                    <label className='header-detail-font' style={{ marginRight: 20 }}>{data?.type}</label>
                    <label className='header-detail-font'>{t('BookingDate')} {formatDate(data?.date)}</label>
                </div>
                <p className='header-amount-font'>₹ {getPrice()} {t('including_GST')}</p>
            </div>
            <div className='tc-container'>
                <label className='tc-heading'>{t('T&C')}</label>
                <div className='tc-checkbox'>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="1" /><label className='checkbox'>{t('T1')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="2" /><label className='checkbox'>{t('T2')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="3" /><label className='checkbox'>{t('T3')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="4" /><label className='checkbox'>{t('T4')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="5" /><label className='checkbox'>{t('T5')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="6" /><label className='checkbox'>{t('T6')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="7" /><label className='checkbox'>{t('T7')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="8" /><label className='checkbox'>{t('T8')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="9" /><label className='checkbox'>{t('T9')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="10" /><label className='checkbox'>{t('T10')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="10" /><label className='checkbox'>{t('T11')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="10" /><label className='checkbox'>{t('T12')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="10" /><label className='checkbox'>{t('T13')}</label>
                    </div>
                    <div>
                        <input type="checkbox" id="terms" name="terms" value="10" /><label className='checkbox'>{t('T14')}</label>
                    </div>
                </div>
                <div className='imp-note-container'>
                    <label className='tc-heading'>{t('Imp_note')}</label>
                    <ul className='checkbox'>
                        <li>{t('No_smoking')}<img src={noSmoke} style={{ width: 20, height: 20 }} alt="" /></li>
                        <li>{t('no_drink')}<img src={noDrinks} style={{ width: 20, height: 20 }} alt="" /></li>
                        <li>{t('no_non_veg')}<img src={noNonveg} style={{ width: 20, height: 20 }} alt="" /></li>
                        <li>{t('no_plastic')}<img src={noPlastic} style={{ width: 20, height: 20 }} alt="" /></li>
                        <li>{t('no_tobacco')}<img src={noTobacco} style={{ width: 20, height: 20 }} alt="" /></li>
                        <li>{t('no_gambling')}<img src={noCards} style={{ width: 20, height: 20 }} alt="" /></li>
                    </ul>
                </div>
                <div>
                    <div>
                        <input type="checkbox" id="hearBy" name="hearBy" value="10" /><label className='checkbox-hearby'>{t('Hear_by')}</label>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 60, marginBottom: 60 }}>
                    {getDisable && <button className="proceed-pay" onClick={handleClick}>{t('pay')}</button>}
                </div>
            </div>

        </div>
    );
}
export default TermsAndCondition;


