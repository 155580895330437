import './App.css';
import Login from './Pages/Login/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './Pages/indexPage';
import Register from './Pages/Register/Register';
import Layout from './Layout';
import Profile from './Pages/Profile/Profile';
import Services from './Pages/Services/Services';
import TermsAndCondition from './Pages/BookNow/TermsAndConditions';
import ProtectedRoute from './routes/ProtectedRoute';
import Payment from './Pages/Payment/Payment';
import DashBoard from './Pages/DashBoard/Dashboard';
import ProtectedRouteAdmin from './routes/ProtectedRouteAdmin';
import Updates from './Pages/Updates/Updates';
import ADTransaction from './Pages/AdminTransactions/ADTransactions';
import ContactManager from './Pages/ContactManager/ContactManager';
import CustomerBooking from './Pages/CustomerBooking/CustomerBooking';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Index />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/Profile" element={<Profile />} />
            {/* <Route path="/PayNow" element={<Payment />} /> */}
          </Route>
            <Route path="/Terms" element={<TermsAndCondition />} />
            <Route path="/completeBooking" element={<ContactManager />} />
          <Route element={<ProtectedRouteAdmin />}>
            <Route path="/dash" element={<DashBoard />} />
            <Route path="/Adtransactions" element={<ADTransaction />} />
            <Route path="/updates" element={<Updates />} />
            <Route path="/customerBooking" element={<CustomerBooking />} />
          </Route>
          <Route path="/Login" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Service" element={<Services />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
