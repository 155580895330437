import React from "react";
import AdminSidebar from "../../Components/DashBar";
import MyCalender from "../CheckAvailablity/Availability";
import { useSelector } from 'react-redux';
import { getUserPhone } from '../../redux/loginSlice';
import { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from "firebase/database";
import { useNavigate } from 'react-router-dom';

const DashBoard = () => {
    const navigate = useNavigate();
    const count = useSelector(getUserPhone);
    const db = getDatabase();
    const [userData, setUserData] = useState([])
    const [loader, setLoader] = useState(true)
    const [isAdmin, setisAdmin] = useState(false)
    const [TransactionListdata, setTransactionList] = useState([])
    useEffect(() => {
        setLoader(true)
        const starCountRef = ref(db, 'Users/' + count + '/data/');
        onValue(starCountRef, (snapshot) => {
            const data1 = snapshot.val();
            if (snapshot.exists()) {
                if (data1.role === "admin" || data1.role === "Manager") {
                    setisAdmin(true)
                } else {
                    setisAdmin(false)
                    navigate('/', { replace: true });
                }
                setUserData(data1)
                setLoader(false)
            }
        });

    }, [db])
    return (
        <div style={{ backgroundColor: 'white', width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
            <AdminSidebar />
            <div style={{ background: 'white', width: '100%', display: 'flex', height: '100%', marginLeft: 245 }}>
                <MyCalender isAdmin={isAdmin} />
            </div>
        </div>
    );
}

export default DashBoard;