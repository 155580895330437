import { createSlice } from '@reduxjs/toolkit';

const userToken = localStorage.getItem('userToken')
    ? localStorage.getItem('userToken')
    : null
const userPhone = localStorage.getItem('userPhone')
    ? localStorage.getItem('userPhone')
    : ""

const initialState = {
    user: userToken,
    phone: userPhone
};


export const userSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        loginUserData: (state, action) => {
            state.user = action.payload;
        },
        savePhone: (state, action) => {
            state.phone = action.payload;
        },
    },
});

export const { loginUserData, savePhone } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getUser = (state) => state.user;
export const getUserPhone = (state) => state.user?.phone;

export default userSlice.reducer;