import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import one from '../assets/1.png';
import two from '../assets/2.png';
import three from '../assets/3.png';
import four from '../assets/4.png';
// import one1 from "../../assets/images/one.png";
// import two2 from "../../assets/images/two.png";
// import three3 from "../../assets/images/three.png";
// import four4 from "../../assets/images/e_hundi.png";
import { useMediaQuery } from 'react-responsive';

export default function SlidingBanner() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 783px)' });
  return (
    <Carousel style={{ height: 'auto', marginRight: 30 }}>
      <Carousel.Item>
        <img
          className="w-100"
          src={isTabletOrMobile ? one : one}
          alt="First slide"
          style={{ height: '100%' }}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={isTabletOrMobile ? two : two}
          alt="Second slide"
          style={{ height: '100%' }}
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={isTabletOrMobile ? three : three}
          alt="Second slide"
          style={{ height: '100%' }}
        />
      </Carousel.Item>
      <Carousel.Item>
        {/* <a href="/donation"> */}
          <img
            className="d-block w-100"
            src={isTabletOrMobile ? four : four}
            alt="Second slide"
            style={{ height: '100%' }}
          />
        {/* </a> */}
      </Carousel.Item>
    </Carousel>
  );
}
